.contraste {
    background-color: #000000 !important;

    .layout-topbar,
    .topbar-left,
    .layout-menu {
        border: 1px solid #ffffff !important;
    }

    .layout-topbar, .topbar-left, .topbar-right, .p-toolbar, .layout-menu-container, .layout-footer {
        background-color: #000000 !important;
    }

    .topbar-right {
        position: static !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    input,
    label,
    ul,
    li,
    th {
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    div.layout-menu,
    div.layout-main,
    div.layout-breadcrumb {
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    div.layout-breadcrumb {
        border: 1px solid #ffffff !important;
    }

    .card, .p-paginator {
        background-color: #000000 !important;
        border: 1px solid #ffffff !important;
        border-radius: 0px !important;
    }

    .p-datatable .p-datatable-tbody > tr {
        background-color: #000000 !important;
    }

    a,
    a span {
        background-color: #000000 !important;
        color: #fff333 !important;
    }

    a span:hover,
    a span:active {
        text-decoration: underline;
    }

    .active-menuitem-routerlink i {
        color: #fff333 !important;
    }

    i {
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    .p-button {
        background-color: #fff333 !important;
        border: 1px solid #fff333 !important;
        color: #ffffff !important;
    }

    #menu-button i {
        background-color: #fff333 !important;
        border-radius: 50% !important;
        color: #000000 !important;
    }

    .p-button span {
        background-color: transparent !important;
        color: #000000 !important;
    }

    .p-paginator .p-paginator-pages a.p-state-active {
        background-color: #fff333 !important;
        color: #000000 !important;
        border: 1px solid #fff333 !important;
    }

    .p-widget-header {
        border: 1px solid #ffffff !important;
        border-top: none !important;
        background-color: #000000 !important;
    }

    .p-dropdown-label,
    .p-inputtext {
        color: #ffffff;
    }

    .p-widget-content {
        background-color: #000000 !important;
        border-color: #ffffff !important;
    }

    .p-dropdown-items li:hover {
        background-color: #fff333 !important;
        color: #000000 !important;
    }

    .p-dropdown-items li:hover > span {
        background-color: transparent !important;
        color: #000000 !important;
    }

    .p-inputtext,
    .p-dropdown-label {
        border-color: #ffffff !important;
    }

    .p-radiobutton-box {
        border-color: #ffffff !important;
    }

    .p-radiobutton-icon {
        background-color: #fff333 !important;
    }

    tr.p-highlight {
        background-color: #fff333 !important;
    }

    tr.p-highlight span {
        background-color: #fff333 !important;
        color: #000000 !important;
    }

    .p-datepicker-calendar tbody tr td a:hover {
        border: 1px solid #ffffff;
        color: #ffffff !important;
    }

    .p-datepicker-calendar tbody tr td .p-highlight {
        background-color: #ffffff !important;
        color: #000000 !important;
    }

    .p-datepicker-calendar tbody tr td .p-state-active {
        background-color: #fff333 !important;
        color: #000000 !important;
    }

    div.topbar-right ul.ultima-menu {
        border: 1px solid #ffffff;
    }
}
